<template>
  <div>

    <group-type-custom-field-row
      v-for="(item, index) in fieldsList"
      :key="item.frontID"
      class="mt-2"
      :fields-list="fieldsList"
      :fields-data="item"
      :field-index="index"
      @removeFieldsRow="removeFieldsRow"
    />

    <b-button
      variant="outline-primary"
      class="d-flex align-items-center justify-content-center w-100 mt-2"
      @click="addNewFieldsRow"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="mr-1"
      />
      Add Field
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'
import GroupTypeCustomFieldRow from '@/views/admin/group/group-forms/components/GroupTypeCustomFieldRow.vue'

export default {
  components: {
    BButton,

    GroupTypeCustomFieldRow,
  },
  props: {
    fieldsList: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      fieldsCount: 0,
      errorMessage: '',
    }
  },
  computed: {
    showError() {
      return this.fieldsList.length === 0
    },
  },
  watch: {
    fieldsList: {
      deep: true,
      handler() {
        this.fieldsCount = this.fieldsList.length
      },
    },
  },
  methods: {
    isFieldKeyExists(key) {
      return this.fieldsList.some(field => field.name === `field_${key}`)
    },
    addNewFieldsRow() {
      do {
        this.fieldsCount += 1
      } while (this.isFieldKeyExists(this.fieldsCount))

      this.fieldsList.push({
        id: uuidv4(),
        frontID: this.fieldsCount,
        type: null,
        type_error: false,
        label: `Field ${this.fieldsCount}`,
        name: `field_${this.fieldsCount}`,
        required: false,
        options: [],
        default: '',
        delete: false,
        order: this.fieldsCount,
        subItems: [],
        newField: true,
        attribute_field_name: null,
        exist: false,
      })
    },
    removeFieldsRow(ID) {
      const curItem = this.fieldsList.findIndex(item => item.frontID === ID)
      this.fieldsList[curItem].delete = true

      if (this.fieldsList[curItem].newField) {
        this.fieldsList.splice(curItem, 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .type-pill {
    padding: 10px;
    background-color: #828589;
    color: white;
    text-align: center;
    border-radius: 10px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }

  .action-button{
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 100;
  }
  .fixed{
    position: fixed;
    transform: translateX(-100%);
  }
</style>
