<template>
  <validation-observer
    ref="groupTypeCustomFieldsRules"
    tag="form"
  >
    <b-list-group-item
      v-if="!fieldsData.delete"
      tag="div"
      class="d-flex align-items-center custom-field-area"
    >
      <b-row>
        <!-- Input Type -->
        <b-col
          sm="10"
        >
          <b-form-group
            label="Input type"
          >
            <validation-provider
              #default="{ errors }"
              name="field type"
              rules="required"
            >
              <b-form-select
                v-model="fieldsData.type"
                :options="getOptions"
                :disabled="fieldsData.readonly || fieldsData.exist"
                :class="{'is-invalid': errors.length > 0 || fieldsData.type_error }"
                @input="toggleError"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Input Delete -->
        <b-col
          sm="2"
          class="align-self-center"
        >
          <div
            style="padding-top: 12px"
          >
            <SquareIcon
              size="21"
              icon="Trash2Icon"
              class="cursor-pointer"
              @onIconClick="removeRow(fieldsData.frontID)"
            />
          </div>
        </b-col>

        <!-- Input Label -->
        <b-col
          sm="10"
        >
          <b-form-group
            label="Input label"
          >
            <b-form-input
              v-model="fieldsData.label"
              :readonly="fieldsData.readonly"
            />
          </b-form-group>
        </b-col>

        <!-- Input Required -->
        <b-col
          sm="2"
          class="align-self-center"
        >
          <b-form-group
            label="Required"
            class="col-form-label-sm"
          >
            <b-form-checkbox
              v-model="fieldsData.required"
              :disabled="fieldsData.readonly"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- Dropdown, Checkbox, Select Options -->
        <!-- Checkbox Default -->
        <b-col
          sm="2"
          class="align-self-center ml-1"
        >
          <template
            v-if="showSubItemsFields(fieldsData) && fieldsData.options"
          >
            <div
              v-for="(option, index) in fieldsData.options"
              :key="index"
              class="d-flex justify-content-center mb-1"
              style="height:65px;padding-top: 7px"
            >
              <b-form-checkbox
                v-if="fieldsData.type === fieldTypes.CHECKBOX"
                v-model="fieldsData.default"
                unchecked-value="not_accepted"
                :value="option"
                :checked="option === fieldsData.default"
              >
                <span class="col-form-label-sm">Default value</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-else
                v-model="fieldsData.default"
                unchecked-value="not_accepted"
                :value="option || index"
                :checked="option === fieldsData.default"
              >
                <span class="col-form-label-sm">Default value</span>
              </b-form-checkbox>
            </div>
          </template>
        </b-col>

        <!-- Option Value -->
        <b-col
          sm="6"
        >
          <div
            v-if="showSubItemsFields(fieldsData) && fieldsData.options"
          >
            <div
              v-for="(option, index) in fieldsData.options"
              :key="index"
              class="mb-1"
            >
              <legend class="bv-no-focus-ring col-form-label pt-0">
                Value
              </legend>
              <b-form-input
                v-model="fieldsData.options[index]"
                :readonly="fieldsData.readonly"
              />
            </div>
            <div
              v-if="!fieldsData.readonly && (fieldsData.type !== 'checkbox' || (fieldsData.type === 'checkbox' && fieldsData.options.length < 1))"
              class="d-flex justify-content-start"
            >
              <b-button
                variant="outline-primary"
                @click="addOption"
              >
                Add
              </b-button>
            </div>
          </div>
        </b-col>

        <!-- Delete Option -->
        <b-col
          sm="2"
        >
          <div
            v-if="showSubItemsFields(fieldsData) && fieldsData.options"
          >
            <div
              v-for="(option, index) in fieldsData.options"
              :key="index"
              class="mb-1"
              style="padding-top: 27px"
            >
              <SquareIcon
                size="21"
                icon="Trash2Icon"
                class="cursor-pointer"
                @onIconClick="removeOption(index)"
              />
            </div>
          </div>
        </b-col>

      </b-row>
    </b-list-group-item>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BListGroupItem,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useCustomField from '@/views/admin/group/group-forms/components/useCustomField'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BListGroupItem,
    BFormInput,
    BFormCheckbox,
    BButton,

    SquareIcon,
  },
  props: {
    fieldsList: {
      type: Array,
      default: () => {
      },
    },
    fieldsData: {
      type: Object,
      default: () => {
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    getOptions() {
      const options = [
        { value: null, text: 'Choose field type' },
      ]
      Object.keys(this.fieldTypes).map(objectKey => {
        const value = this.fieldTypes[objectKey]
        options.push({ value, text: this.getFieldNameForLayout(value) })
        return value
      })
      return options
    },
  },
  setup() {
    const {
      fieldTypes,
    } = useCustomField()

    return {
      fieldTypes,
    }
  },
  methods: {
    addOption() {
      this.fieldsData.options.push('')
    },
    removeOption(index) {
      this.fieldsData.options.splice(index, 1)
    },
    getFieldNameForLayout(value) {
      const str = value.replace('_', ' ')
      const lower = str.toLowerCase()
      return str.charAt(0).toUpperCase() + lower.slice(1)
    },
    showSubItemsFields(item) {
      const showFieldsIn = [this.fieldTypes.DROPDOWN, this.fieldTypes.CHECKBOX, this.fieldTypes.RADIO_BUTTON, this.fieldTypes.MULTI_SELECT]
      return showFieldsIn.includes(item.type)
    },
    removeRow(rowID) {
      this.$emit('removeFieldsRow', rowID)
    },
    replaceValue(val) {
      let str = val.toString().replace(/\s+/g, '_').toLowerCase()

      const checkSameName = this.fieldsList
        .filter(item => str === item.name)
        .filter(item => this.fieldsData.frontID !== item.frontID)

      if (checkSameName.length > 0) str = `${str}_${new Date().getTime()}`

      this.fieldsData.name = str
    },
    toggleError(val) {
      if (val !== null) {
        this.fieldsData.type_error = false
      }
    },
  },
}
</script>

<style>
  .custom-field-area {
    background: #FBFBFC;
  }
  legend {
    white-space: nowrap;
  }
</style>
