<template>
  <!-- Create Group Type -->
  <b-modal
    id="add-group-type"
    ref="add-group-type"
    centered
    title="Create Group Type"
    ok-title="Create"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="createGroupType"
    @show="prepareData"
  >
    <validation-observer
      ref="groupRules"
      tag="form"
    >
      <form
        ref="form"
      >
        <b-row>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Group Type Name"
            >
              <validation-provider
                #default="{ errors }"
                name="group name"
                rules="required"
              >
                <b-form-input
                  v-model="groupType.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
            align-self="center"
          >
            <validation-provider
              rules=""
              vid="subgroup"
            >
              <b-form-checkbox
                v-model="groupType.isSubgroup"
              >
                Is Subgroup
              </b-form-checkbox>
            </validation-provider>
          </b-col>

          <b-col
            sm="6"
            class="mb-1"
          >
            <validation-provider
              #default="{ errors }"
              name="group type"
              :rules="{ required: groupType.isSubgroup }"
            >
              <v-select
                v-model="groupType.parentType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupTypeOptions"
                :placeholder="'Parent Group Type'"
                :clearable="false"
                value="value"
                label="text"
                :reduce="type => type.value"
                :class="{'is-invalid': errors.length > 0 }"
                :disabled="!groupType.isSubgroup"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col
            sm="12"
            class="mt-2"
          >
            <hr>
            <span>Custom Fields</span>
            <group-type-custom-fields
              :fields-list="customFieldsList"
            />
          </b-col>

          <b-col
            sm="12"
            class="mt-2"
          >
            <hr>
            <span>Auto-Assignments</span>
            <group-type-auto-assigns
              :assigns-list="assignsList"
              :custom-fields-list="customFieldsList"
            />
          </b-col>

          <b-col
            sm="12"
            class="mt-2"
          >
            <hr>
            <span>Defaults</span>
          </b-col>

          <group-admin-rules-component
            :group-data="groupType.defaults"
            :custom-fields-list="customFieldsList"
            :only-group-type-rule="true"
          />

        </b-row>
      </form>
    </validation-observer>

    <div
      v-if="validationErrors.length"
      class="form-group pt-1"
    >
      <p
        v-for="(validationError, index) in validationErrors"
        :key="index"
        class="text-center text-danger"
      >
        {{ validationError }}
      </p>
    </div>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, VBModal, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, requiredIf } from '@validations'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/group-types/groupTypeStoreModule'
import GroupAdminRulesComponent from '@/views/admin/group/group-forms/components/GroupAdminRulesComponent.vue'
import useGroupList from '@/views/admin/group/group-list/useGroupList'
import GroupTypeCustomFields from '@/views/admin/group/group-forms/components/GroupTypeCustomFields.vue'
import GroupTypeAutoAssigns from '@/views/admin/group/group-forms/components/GroupTypeAutoAssigns.vue'

export default {
  name: 'GroupCreateTypeModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,

    vSelect,
    GroupAdminRulesComponent,
    GroupTypeCustomFields,
    GroupTypeAutoAssigns,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      groupTypeOptions: [],
      groupType: {},
      validationErrors: [],
      required,
      requiredIf,
      customFieldsList: [],
      assignsList: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-create-group-type'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      postGroupType,
      programId,
    } = useGroupList()

    return {
      postGroupType,
      programId,
    }
  },
  methods: {
    prepareData() {
      this.initCreateGroupFormData()
      this.fetchGroupTypesForSelect()
    },
    async fetchGroupTypesForSelect() {
      await this.$store.dispatch('app-create-group-type/fetchGroupTypesForSelect', { programId: this.programId })
        .then(response => {
          this.groupTypeOptions = response.data.data
          this.groupTypeStatus = 'Select Group Type'
        })
    },
    initCreateGroupFormData() {
      this.groupType = {
        name: null,
        parentType: null,
        isSubgroup: false,
        defaults: {
          is_visible: false,
          is_allow_use_selection: false,
          is_restrict_single_group_type: false,
          is_capacity_enforcement: false,
          is_allow_viewing_other_members: false,
          is_limit_selection: false,
          limit_selection_conditions: [],
          is_bulk_create: false,
          has_tuition_fee: false,
        },
      }

      this.customFieldsList = []
      this.assignsList = []
    },
    async createGroupType(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.validationErrors = []
      const formData = {
        name: this.groupType.name,
        parent_id: this.groupType.parentType,
        program_id: this.programId,
        group_settings: this.groupType.defaults,
        custom_fields: this.customFieldsList,
        auto_assigns: this.assignsList,
      }

      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        this.$refs.groupRules.validate().then(success => {
          if (success) {
            this.postGroupType(formData)
              .then(response => {
                if (response.status === 422) {
                  const validationErrors = Object.values(response.data.errors)
                  this.validationErrors = validationErrors.flat()
                } else {
                  this.validationErrors = []
                  this.$refs['add-group-type'].toggle('#toggle-btn')
                  this.$emit('reload')
                }
              })
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-autosuggest.scss';

  .is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid #ea5455;
    }
  }
</style>
