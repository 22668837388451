import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useGroupTypesList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'is_subgroup', label: 'Is Sub-group', sortable: true },
    { key: 'parent_name', label: 'Parent Group', sortable: true },
    { key: 'groups_count', label: 'Groups', sortable: true },
    { key: 'tuition', label: 'Tuition/Fee', sortable: false },
    { key: 'is_visible', label: 'Visible', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('groupTypesPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterDataStatus = ref('')
  const filterDataTuition = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataStatus.value = ''
    filterDataTuition.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch(programId, () => {
    refetchData()
  })

  watch([currentPage, perPage, searchQuery, sortBy, filterDataStatus, filterDataTuition],
    ([currentPageVal, perPageVal, searchQueryVal, sortByVal, filterDataStatusVal, filterDataTuitionVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, sortByPrevVal, filterDataStatusPrevVal, filterDataTuitionPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-group-types', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-group-types', 'perPage', perPageVal)
      }
      if (sortByVal !== sortByPrevVal) {
        pushStateFiltersParams(root, 'admin-group-types', 'sortBy', sortByVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-group-types', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-group-types', 'filterDataStatus', filterDataStatusVal)
      }
      if (filterDataTuitionVal !== filterDataTuitionPrevVal) {
        pushStateFiltersParams(root, 'admin-group-types', 'filterDataTuition', filterDataTuitionVal)
      }
    })

  const fetchGroupTypesList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      programId: programId.value,
      exceptProgramType: 'Program',
    }

    if (searchQuery.value) {
      payloadData.name = searchQuery.value
    }
    if (filterDataStatus.value) {
      payloadData.is_visible = filterDataStatus.value
    }
    if (filterDataTuition.value) {
      payloadData.hasTuition = filterDataTuition.value === 'Tuition' ? 1 : 0
    }
    store
      .dispatch('app-group-type/fetchGroupTypesList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching group types list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const postGroupType = queryParams => axios
    .post('/auth/group-types', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Group Type was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Group Type',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const deleteGroupType = ID => axios
    .delete(`auth/group-types/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting group type',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting group type',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const updateGroupType = (id, queryParams) => axios
    .put(`/auth/group-types/${id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success updating Group Type',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error editing Group Type',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const fetchGroupType = taskID => axios
    .get(`/auth/group-types/${taskID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Group Type',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'danger'
    return 'primary'
  }

  const actionOptions = []

  const filterVisibleStatusOptions = [
    { value: 'active', label: 'True' },
    { value: 'inactive', label: 'False' },
  ]

  const filterTuitionFeeOptions = [
    { value: 'Tuition', label: 'Tuition' },
    { value: 'Fee', label: 'Fee' },
  ]

  return {
    fetchGroupTypesList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterDataStatus,
    filterDataTuition,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,
    programId,

    statusFilter,
    actionOptions,
    filterVisibleStatusOptions,
    filterTuitionFeeOptions,

    resolveStatusVariant,

    refetchData,
    clearFilters,

    postGroupType,
    deleteGroupType,
    updateGroupType,
    fetchGroupType,
  }
}
